import React, { Suspense } from 'react';
import Routes from './routing/routes';
// import { getTime } from './utils/themeChanger';

function App() {
  // let darkmode = getTime(new Date());

  // Dark from 08:00pm to 07:00am (20:00 to 07:00)
  // if (darkmode >= 20 && darkmode > 6) {
  //   document.body.classList.add('dark');
  // } else if (darkmode > 7 && darkmode < 17) {
  //   document.body.classList.remove('dark');
  // }

  return (
    <Suspense fallback={<></>}>
      <main>
        <Routes />
      </main>
    </Suspense>
  );
}

export default App;
