import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../utils/scrollToTop';
import DocumentMeta from 'react-document-meta';
import SnackbarProvider from 'react-simple-snackbar'

const PageLanding = import('../pages/landing/landing');
const PageDocumentation = import('../pages/documentation/documentation');
const PageOnPremise = import('../pages/documentation/onpremise/onpremise');
const PageGeneraleAPI = import('../pages/documentation/api/generale');
const PageGeneraleSDK = import('../pages/documentation/sdk/generale');
const AnonymizationInterne = import('../pages/documentation/onpremise/anonymization/anonymizationInterne');
const PageCookies = import('../pages/cookies/cookies');
const PageTerms = import('../pages/terms/terms');
const PageSubscriptionForm = import('../pages/subscription/subscription-form');
const PageSubscriptionEnd = import('../pages/subscription/subscription-end');
const PagePricing = import('../pages/pricing/Pricing');

function Container() {
    const LazyLoadedLanding = React.lazy(() => PageLanding);
    const LazyLoadedDocumentation = React.lazy(() => PageDocumentation);
    const LazyLoadedPageOnPremise = React.lazy(() => PageOnPremise);
    const LazyLoadedPageGeneraleAPI = React.lazy(() => PageGeneraleAPI);
    const LazyLoadedPageGeneraleSDK = React.lazy(() => PageGeneraleSDK);
    const LazyLoadedAnonymizationInterne = React.lazy(() => AnonymizationInterne);
    const LazyLoadedCookies = React.lazy(() => PageCookies);
    const LazyLoadedTerms = React.lazy(() => PageTerms);
    const LazyLoadedSubscriptionForm = React.lazy(() => PageSubscriptionForm);
    const LazyLoadedSubscriptionEnd = React.lazy(() => PageSubscriptionEnd);
    const LazyLoadedPricing = React.lazy(() => PagePricing);

    const meta = {};

    return (
        <DocumentMeta {...meta} extend>
            <SnackbarProvider>
                <Router>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/" component={LazyLoadedLanding} />
                        <Route exact path="/api/global-documentation" component={LazyLoadedPageGeneraleAPI} />
                        <Route exact path="/sdk/global-documentation" component={LazyLoadedPageGeneraleSDK} />
                        <Route exact path="/onpremise/:documentation_id" component={LazyLoadedPageOnPremise} />
                        <Route exact path="/services/:documentation_id" component={LazyLoadedDocumentation} />
                        <Route exact path="/onpremise/anonymization/api-internal"
                            component={LazyLoadedAnonymizationInterne} />
                        <Route exact path="/cookies-policy/" component={LazyLoadedCookies} />
                        <Route exact path="/terms-of-use/" component={LazyLoadedTerms} />
                        <Route exact path="/pricing/" component={LazyLoadedPricing} />
                        <Route exact path="/subscription/:origin/payable/:support" component={LazyLoadedSubscriptionForm} />
                        <Route exact path="/subscription/:origin/payable/:support/:firstName/success" component={LazyLoadedSubscriptionEnd} />
                        <Route exact path="/subscription/:origin/trial" component={LazyLoadedSubscriptionForm} />
                        <Route exact path="/subscription/:origin/trial/:firstName/success" component={LazyLoadedSubscriptionEnd} />
                    </Switch>
                </Router>
            </SnackbarProvider>
        </DocumentMeta>
    )
}

export default Container;
